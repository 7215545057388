import logo from './mcidle.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Coming Soon...</h1>
        <a href="https://l.mcidle.com/discord" className="App-logoa"><img src={logo} className="App-logo" alt="logo" /></a>
      </header>
    </div>
  );
}

export default App;
